@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body {
  /* font-family: 'Red Hat Display', sans-serif !important; */
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-poppins{
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.02em;
  font-weight: 400;
}

.text-grotesk{
  font-family: 'Space Grotesk', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code, .text-code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-layout-sider{
  background: #fff !important;
}

.ant-page-header{
  background-color: transparent !important;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}

.text-center{
  text-align: center;
}

.text-small label,
.text-small input,
.text-small textarea{
  font-size: .9em !important;
}


.ant-tree.tree-rounded .ant-tree-node-content-wrapper,
.unit-rounded{
  padding: 4px 12px 3px 5px;
  box-shadow: 0 0 0 1px #dadce0;
  background: rgba(0,0,0,.03);
  border-radius: 16px;
  margin-bottom: 6px;
  transition: all .3s;
}

.ant-tree.tree-rounded .ant-tree-node-content-wrapper.ant-tree-node-selected {
  box-shadow: 0 0 0px 2px #1890ff;
}

.ant-tree.tree-rounded .ant-tree-node-content-wrapper:hover{
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 2px 10px -1px rgb(0 0 0 / 12%);
}

.tree-avatar-rounded{
  margin-right: 6px!important;
  margin-left: -3px!important;
  margin-top: -2px!important;
}


.opacity-15{
  opacity: .15 !important;
}

.opacity-25{
  opacity: .25 !important;
}