.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .border-sour{
//   border-color: #e74243;
// }

.bg-sour{
  background-color: var(--ant-color-primary)!important;
  background: var(--ant-color-primary)!important;
}

.text-sour{
  color: var(--ant-color-primary) !important;
}

.bg-primary {
  background-color: #1890ff !important;
  background: #1890ff !important;
}

/**** Overrides to antdesign ****/

.sour-antd-tag{
  margin: .5em;
  padding: 5px 12px;
  font-size: .9em;
  & .ant-tag-close-icon{
    font-size: .9em;
  }
}

.cursor-pointer{
  cursor: pointer;
}

.cursor-na{
  cursor: not-allowed;
}

a:hover {
  text-decoration: none;
}


#launcher-frame{
  display: none !important;
  bottom: 46px !important;
  right: 6px !important;
  opacity: .9;

  &:hover{
    opacity: 1;
  }
}

.ant-menu-horizontal>.ant-menu-item a:hover, .ant-menu-horizontal>.ant-menu-item.ant-menu-item-selected a {
  color: var(--ant-color-primary);
}

.sour-header {
  position: fixed;
  z-index: 10;
  width: 100%;

  & .ant-menu-submenu-title:hover{
    color: var(--ant-color-primary) !important;
  }

  &> .ant-menu:not(.ant-menu-dark){
    &>.ant-menu-submenu-selected,
    &>.ant-menu-item-selected,
    &>.ant-menu-submenu:hover,
    &>.ant-menu-item:hover{
      color: var(--ant-color-primary) !important;

      &:after{
        border-bottom: 2px solid var(--ant-color-primary) !important;
      }
      & a {
        color: var(--ant-color-primary) !important;
      }
    }
  }
}

.sour-layout{
  & li.ant-menu-item.ant-menu-item-only-child {
    padding-left: 32px !important;
  }
}

.sour-sidebar{
  height: 100vh;
  // top: 64px;
  position: fixed !important;
  overflow: hidden;
  left: 0;

  & .ant-menu.ant-menu-inline-collapsed>.ant-menu-item,
  & .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
  & .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
  & .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title{
    padding: 0 calc(50% - 14px);
  }

  &.ant-layout-sider-collapsed .ant-menu-item svg {
    margin-top: 6px !important;
  }

  & .ant-menu-item svg,
  & .ant-menu-submenu svg{
    font-size: 26px !important;
    line-height: 1em !important;
    margin-left: 0 !important;
    margin-top: 7px !important;
  }
}

.ant-layout-sider-collapsed{
  & .ant-menu-submenu-title > span {
    display: none;
  }
}

.sour-layout-content{
  margin-left: 190px;
  &.sour-layout-content-header{
    margin-top: 64px;
  }
  &.sour-content-sidebar-collapsed{
    margin-left: 80px;
  }
}

.ant-statistic.text-white{
  & .ant-statistic-title,
  & .ant-statistic-content{
    color: white;
  }
}

.ant-card {
  &.rounded-10{
    border-radius: 1em;
  }
}

.z-index-2{
  z-index: 2 !important;
}

label {
  margin-bottom: 0rem;
}

.overflow-x-hover{
  overflow-x: auto;
  &:active::-webkit-scrollbar-thumb,
  &:focus::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: var(--ant-color-primary);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

.overflow-y-hover{
  overflow-x: hidden;
  overflow-y: auto;
  &:active::-webkit-scrollbar-thumb,
  &:focus::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
    background-color: var(--ant-color-primary);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

.ant-segmented-item-selected{
  background-color: var(--ant-color-primary) !important;
  color: #fff !important;
}